.professional__experience-location {
  margin-bottom: 1em;
}

.education__dates {
    margin: .1em;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--clr-fg-alt);
}

.skill__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: .5em 0;
}

.company_name {
  color: var(--clr-primary) !important;
}