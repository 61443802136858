.about {
  flex-direction: column;
  margin-top: 3em;
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 800px;
}

.about__desc {
  margin-top: 2.4em;
}

.about__contact {
  margin-top: 2em;
}

.about_contact__title {
  margin-right: .5em;
  font-size: 21px;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .link--icon .MuiSvgIcon-root{
  height: 1.5em;
  width: 1.5em;
}

.about .btn--outline {
  margin-right: 1em;
}

.about_name__container {
  margin-bottom: 1.2em;
}

.responsive-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}
