.scroll-top {
  position: fixed;
  bottom: 2em;
  right: 4em;
  background-color: transparent;
}

.scroll-top:hover {
  transform: scale(1.3);
  transition: all .15s ease-in-out;
}

@media (max-width: 900px) {
  .scroll-top {
    display: none;
  }
}
